<template>
  <div v-if="metadata != null">
    <custom-cg-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      translatePrefix="controlTower.pages"
    />
    <comment-dialog
      :show="showComments"
      :currentRow="commentRow"
      :comments="comments"
      :service="serviceComment"
      :fieldParentId="title + 'Id'"
      :page="title"
      :fields="fields"
      @input="showComments = $event"
    />
  </div>
</template>

<script>
import { CustomCgCrudMetaView, CommentDialog } from '@cargill/shared'
import service from '../api/takeUpProducerMonitoringService'
import serviceComment from '../api/takeUpProducerMonitoringCommentService'
import StuffingPortalStatusCellRenderer from '../components/stuffing-portal-table/StuffingPortalStatusCellRenderer'
import Vue from 'vue'

export default {
  components: { CustomCgCrudMetaView, CommentDialog },
  data() {
    return {
      title: 'takeUpProducerMonitoring',
      metadata: null,
      crudService: service,
      serviceComment: serviceComment,
      showComments: false,
      commentRow: {},
      comments: [],
      fields: [
        'company',
        'contract',
        'cropYear',
        'month',
        'producer',
        'classifierCode',
        'status'
      ]
    }
  },
  methods: {
    translate(key) {
      return this.$t(`controlTower.pages.${this.title}.${key}`)
    },
    async getMeta() {
      const meta = await service.getMetaWithValidation()

      const lastUpdateClassifier = meta.fields.find(
        (x) => x.id == 'lastUpdateClassifier'
      )
      delete lastUpdateClassifier.cellRenderer
      lastUpdateClassifier.cellRendererFramework = Vue.extend(
        StuffingPortalStatusCellRenderer
      )
      meta.actions = [
        {
          title: () => this.$t('controlTower.pages.stuffingPortal.comments'),
          className: () => 'fas fa-comment-alt-lines',
          enable: true,
          callback: async ({ node }) => {
            this.commentRow = node.data
            await this.getComments()
            this.showComments = true
          }
        }
      ]
      return meta
    },
    async getComments() {
      const filter = `sort=date:desc&filterQuery=takeUpProducerMonitoringId%3Din%3A${this.commentRow.id}`
      this.comments = await this.serviceComment.getAll(filter)
    }
  },
  created() {
    this.getMeta().then((meta) => {
      this.metadata = meta
    })
  }
}
</script>

import {
  api,
  createCrudService,
  responseToOptionsInContext
} from '@cargill/shared'
import _ from 'lodash'

const service = createCrudService(
  '/api/controltower/takeUpProducerMonitoring',
  api,
  {
    importLogUrl: '/api/controltower/importLog'
  }
)

service.processValidationContext = (component, ctx, meta) => {
  responseToOptionsInContext(ctx)
  // const fieldsById = _.keyBy(meta.fields, (field) => field.id)
  // Ignora esse campo no front, pois não tem tratamento para select e é não editável
  // fieldsById.location.options = ctx.locationOptions
}

export default service
